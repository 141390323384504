import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
const ProduitsRappelListeEMP = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [produits, setProduits] = useState([]);
  const { refreshToken } = useLogin();

  const location = useLocation();
  const treate = location?.state?.treate;
  console.log(treate, "treate");
  const [traiter, setTraiter] = useState(false);
  const [treated, setTreated] = useState(false);
  const [notTreated, setNotTreated] = useState(treate === "not_traated" ? true : false);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modification, setModification] = useState(false);
  const [update, setUpdate] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_RECALL_NOTIFS_EMPLOYEE, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        treated: false,
      },
    })
      .then((res) => {
        setProduits(res?.data?.results);
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [modification, treated, notTreated]);
  const handlePagination = (url) => {
    if (url) {
      Api.get(url, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      })

        .then((res) => {
          setProduits((data) => {
            return res?.data?.results;
          });
          setResponse(res?.data);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              console.log(res, "new user");
              setUpdate({ state: !update, url: url });
            });
          } else {
            toast.error(err?.response?.data?.details);
          }
          setLoading(false);
        });
    }
  };

  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton
            onClick={() => {
              handlePagination(response?.previous);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton
            onClick={() => {
              handlePagination(response?.next);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  useEffect(() => {
    handlePagination(update?.url);
  }, [update]);
  const traiterNoProd = (id) => {
    Api.post(
      Urls.TreatNoProductRecall(id),
      {},
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(t("forms.success.update"));
        setModification(!modification);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setTraiter({ state: !traiter, data: id });
          });
        } else {
          toast.error(err?.response?.data?.details);
        }
      });
  };
  useEffect(() => {
    if (traiter?.data) traiter(traiter.data);
  }, [traiter]);
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="24px">
          {produits?.map((prod) => (
            <>
              {!prod?.no_corresponding_product && (
                <Box
                  onClick={() => {
                    navigate("/employee/gestion-des-rappels/liste/" + prod?.id);
                  }}
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  gap="20px"
                  alignItems="center"
                  backgroundColor="rgba(211,211,211 , 0.5)"
                  borderRadius="16px"
                  padding="10px"
                >
                  <Box sx={{ flex: "1" }}>
                    <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.product?.use_admin_image ? (prod.product?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.product_image?.product_image : imagePlaceholder) : prod.product?.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.private_product_image : imagePlaceholder} alt="produit" />
                  </Box>
                  <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.product?.location ? prod?.product?.location : "-"}</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.note")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.note ? `${prod.note.substring(0, 30)}${prod.note.length > 30 ? "..." : ""}` : "-"}</Typography>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              {prod?.no_corresponding_product && (
                <Box sx={{ cursor: "pointer" }} display="flex" gap="20px" alignItems="center" backgroundColor="rgba(211,211,211 , 0.5)" borderRadius="16px" padding="10px">
                  <Box sx={{ flex: "1" }}>
                    <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.product?.use_admin_image ? (prod.product?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.product_image?.product_image : imagePlaceholder) : prod.product?.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.product?.private_product_image : imagePlaceholder} alt="produit" />
                  </Box>
                  <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{t("no_product")}</Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.designation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>-</Typography>
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.product?.location ? prod?.product?.location : "-"}</Typography>
                    </Typography>

                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                      {t("forms.labels.note")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.note}</Typography>
                    </Typography>
                    <Box>
                      <Button onClick={() => traiterNoProd(prod?.id)} variant="primary">
                        {t("traiter")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          ))}
          <Box marginBottom="24px">
            <Pagination />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ProduitsRappelListeEMP;
