import { Box, Button } from "@mui/material";
import NavbarEmployee from "./NavbarEmployer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const EmployeeSharedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locations_fr = [
    { path: "/employee/gestion-des-traiter/liste", name: "À Traiter" },
    { path: "/employee/gestion-des-rappels/liste", name: "Rappel" },
    { path: "/employee/gestion-des-suivis/liste", name: "Suivi" },
    { path: "/employee/gestion-des-produits/liste", name: "Produits" },
  ];
  const location_en = [
    { path: "/employee/gestion-des-traiter/liste", name: "To Process" },
    { path: "/employee/gestion-des-rappels/liste", name: "Recall" },
    { path: "/employee/gestion-des-suivis/liste", name: "Actions" },
    { path: "/employee/gestion-des-produits/liste", name: "Products" },
  ];
  const location_es = [
    { path: "/employee/gestion-des-traiter/liste", name: "A Procesar" },
    { path: "/employee/gestion-des-rappels/liste", name: "Retiro" },
    { path: "/employee/gestion-des-suivis/liste", name: "Acciones" },
    { path: "/employee/gestion-des-produits/liste", name: "Productos" },
  ];
  const lang = localStorage.getItem("i18nextLng");
  const locations = lang === "fr" ? locations_fr : lang === "es" ? location_es : lang === "en" ? location_en : [];
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <NavbarEmployee />
      <Box display={"flex"} justifyContent="center" maxWidth={"100%"}>
        <Box padding="0 10px" maxWidth="370px">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
export default EmployeeSharedLayout;
