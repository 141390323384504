export const Urls = {
  //Auth
  AUTH: "/auth/login",
  //password reovery with email
  PASSWORD_RECOVERY_STEP1: "/auth/passwordreset",
  //new password
  PASSWORD_RECOVERY_STEP2: "/auth/passwordreset/confirm",
  //get bannieres
  GET_BANNIERES: "/admin/franchises",
  //refreshToken
  REFRESH_TOKEN: "/auth/token/refresh",
  //add franchise
  ADD_Franchise: "/admin/franchises",
  //get franchise by id
  GetFranchise: (id) => `/admin/franchises/${id}`,
  //update franchise
  UpdateFranchise: (id) => `/admin/franchises/${id}`,
  //delete franchise
  DeleteFranchise: (id) => `/admin/franchises/${id}`,
  //get managers
  GET_MANAGERS: "/admin/managers",
  //get databases
  GET_BDS: "/admin/product_dbs",
  //get dbs by franchise id
  GetDbsByFrID: (id) => `/admin/franchises/${id}/product_dbs`,
  //Add marchand
  ADD_MANAGERS: "/admin/managers",
  //rate
  SubmitProcessRate: "/store_manager/days_preference",

  GETMANAGERPREFSDAYS: "/store_manager/days_preference",
  //duplicate db
  DUPLICATE_DB: "/admin/product_dbs/duplicate",
  //get product db by id
  GetDbByID: (id) => `/admin/product_dbs/${id}`,
  // update database
  UpdateDatabase: (id) => `/admin/product_dbs/${id}`,
  //delete franchise
  DeleteDbByID: (id) => `/admin/product_dbs/${id}`,
  //get marchand by id
  GetMarchandByID: (id) => `/admin/managers/${id}/profile`,
  // get marchand credentials
  GetMarchandCredentials: (id) => `/admin/managers/${id}/credentials`,
  // update marchand credentials
  UpdateMarchandCredentials: (id) => `/admin/managers/${id}/change_credentials`,

  //update manager by id
  UpdateManagerProfileById: (id) => `/admin/managers/${id}/profile`,
  //UPDATE USER PASS
  UpdateUserPassword: (id) => `/admin/users/${id}/change_password`,
  //UPDATE USER PASS
  UpdateUserUsername: (id) => `/admin/users/${id}/change_username`,
  //UPDATE USER PASS
  UpdateUserEmail: (id) => `/admin/users/${id}/change_email`,
  //get all products
  GET_ALL_PRODS: "/admin/products",
  //get all lanceurs dalertes
  GET_ALL_ALERT_LAUNCHERS: "/admin/alert_launchers",
  //delete marchand by id,
  DeleteMarchandByID: (id) => `/admin/managers/${id}/profile`,
  //add lanceurs d'alerte
  ADD_LANCEURS: "/admin/alert_launchers",
  //get alerte launcher by iD
  GetAlertLauncherByID: (id) => `/admin/alert_launchers/${id}/profile`,
  //update launcher alert
  UpdateAlertLauncher: (id) => `/admin/alert_launchers/${id}/profile`,
  // ADD PRODUITS
  ADD_PRODUCT: "/admin/products",
  //get product by id
  GetProdByID: (id) => `/admin/products/${id}`,
  //get products by db ID
  GetProdByDBID: (id) => `/admin/product_dbs/${id}/products`,

  searchProductsAdmin: (id) => `/admin/products/search?q=${id}`,
  searchProductsStoreManager: (id) => `/store_manager/products/search?q=${id}`,
  searchProductsAlert: (id) => `/alert_launcher/products/search?q=${id}`,

  //update a specific departments
  UpdateDepByID: (id) => `/admin/franchises/departments/${id}`,
  //update a specific subDep
  UpdateSubDepByID: (id) => `/admin/franchises/sub_departments/${id}`,
  //get franchise for alert launcher
  GET_FRANCHISE_ALERT_LAUNCHER: "/alert_launcher/franchise",
  // get products for a alert laucnher
  GET_PRODUITS_ALERT_LAUNCHER: "/alert_launcher/products",
  //patch recalled product
  UpdateRecallProductByID: `/alert_launcher/recall_notifications`,
  //get product for alert launcher by id
  GetProductForAlertLauncherByID: (id) => `/alert_launcher/products/${id}`,
  // get recalled products
  GET_RECALLED_PRODUCTS: "/alert_launcher/recall_notifications",
  // blacklist refresh token
  BLACK_LIST_REFRESH_TOKEN: "/auth/token/blacklist",
  //get recall otification by id
  GetRecallNotificationByID: (id) => `/alert_launcher/recall_notifications/${id}`,
  //get produits a traiter
  GET_PRODUCT_IMMINENT_EXP: "/store_manager/products/imminent_expiration",
  //store manager productis
  GET_STORE_MANAGER_PRODUCTS: "/store_manager/products",
  //store manager recall notifications
  GET_STORE_MANAGER_RECALL_NOTIFS: "/store_manager/recall_notifications",
  // get action for store manager
  GET_ACTIONS_STORE_MANAGER: "/store_manager/actions",
  //get recall notif manager by id
  GetRecallNotificationManagerByID: (id) => `/store_manager/recall_notifications/${id}`,
  //get employees for manager
  GET_EMPLOYEES: "/store_manager/employees",
  //add employee
  ADD_EMPLOYEE: "/store_manager/employees",
  //get employee by id
  GetEmloyeeByID: (id) => `/store_manager/employees/${id}/profile`,
  //update employee by id
  UpdateEmployeeByID: (id) => `/store_manager/employees/${id}/profile`,
  //update employee password
  UpdateEmployeePassword: (id) => `/store_manager/employees/${id}/change_password`,
  UpdateEmployeeUsername: (id) => `/store_manager/employees/${id}/change_username`,
  UpdateEmployeeEmail: (id) => `/store_manager/employees/${id}/change_email`,
  //get store manager preferences
  GET_STORE_MANAGER_PREFERENCES: "/store_manager/email_preferences",
  //update store manager prefs
  UPDATE_STORE_MANAGER_PREFS: "/store_manager/email_preferences",
  //get franchise infos for manager
  GET_FRANCHISE_INFOS_MANAGER: "/store_manager/franchise",
  //create prod
  CREATE_PROD_MANAGER: "/store_manager/products",
  //GET A PRODUCT BY ID MANAGER
  GetProduitIDManager: (id) => `/store_manager/products/${id}`,
  //update product manager
  UpdateProductManager: (id) => `store_manager/products/${id}`,
  // get actions for a certain product
  GetAtionsForAcertainProduct: (id) => `/store_manager/products/${id}/actions`,
  //update image product
  updateimageproduct: (id) => `/store_manager/products/${id}/update_private_image`,
  //get franchise marchand
  GetFranchiseMarchand: "/store_manager/franchise",
  //get expired products
  Get_expired_products: "/store_manager/products/expired",
  //get actions for employee
  GET_ACTIONS_EMPLOYEE: "/employee/actions",
  //get franchise employee
  GET_FRANCHISE_EMPLOYEE: "/employee/franchise",
  //get products for employee
  GET_PRODUITS_EMPLOYEE: "/employee/products",
  ADD_PRODUCT_EMPLOYEE: "/employee/products/request_addition",
  //change date employee
  ChangeDate: (id) => `/employee/products/${id}/update_expiration_date/expiration_date_available`,
  //change date no date
  ChangeDateNoDate: (id) => `/employee/products/${id}/update_expiration_date/no_expiration_date`,
  //treateUnsold
  TreatUnsoldWithDate: (id) => `/employee/products/${id}/treat_unsold_product/expiration_date_available`,
  TreatUnsoldWithNoDate: (id) => `/employee/products/${id}/treat_unsold_product/no_expiration_date`,
  //get produit a traiter
  GET_PRODUITS_A_TRAITER: "/employee/products/imminent_expiration",
  //get recall notifs employee
  GET_RECALL_NOTIFS_EMPLOYEE: "/employee/recall_notifications",
  //search prod employee
  SearchProductEmployee: (id) => `/employee/products/search?q=${id}`,
  //treate no product recall
  TreatNoProductRecall: (id) => `/employee/recall_notifications/${id}/treat_notification/no_product_available`,
  //get recall notif by id
  GetRecallNotifByID: (id) => `/employee/recall_notifications/${id}`,
  //update store employee profile
  UpdateStoreEmployeeProfile: (id) => `/store_manager/employees/${id}/profile`,
  //generate pdf :
  GenereatePdf: "/store_manager/actions/report",
  //treat recall
  TreatRecallNoDate: (id) => `/employee/recall_notifications/${id}/treat_notification/product_available/no_expiration_date`,
  TreatRecallWithDate: (id) => `/employee/recall_notifications/${id}/treat_notification/product_available/expiration_date_available`,
  //desactiver manager
  deactivateManager: (id) => `/admin/managers/${id}/deactivate_user_account`,
  //get action by id
  getActionByID: (id) => `/store_manager/actions/${id}`,
  updateActionByID: (id) => `/store_manager/actions/${id}`,
};
