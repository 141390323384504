import { Box, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import { action_types_en, action_types_es, action_types_fr } from "../../../assets/data/ActionTypes";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
const ListeSuivisEMP = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [actions, setActions] = useState([]);
  const [byMe, setByMe] = useState(false);
  const lang = localStorage.getItem("i18nextLng");
  const { refreshToken } = useLogin();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modification, setModification] = useState(false);
  const [update, setUpdate] = useState(false);
  const [response, setResponse] = useState({});
  let action_types = lang === "fr" ? action_types_fr : lang === "en" ? action_types_en : lang === "es" ? action_types_es : {};

  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_ACTIONS_EMPLOYEE, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        is_owner: byMe,
        offset: 0,
        limit: 25,
      },
    })
      .then((res) => {
        setActions(res?.data?.results);
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [byMe, modification]);
  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setActions((data) => {
          return res?.data?.results;
        });
        setResponse(res?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdate({ state: !update, url: url });
          });
        } else {
          toast.error(err?.response?.data?.details);
        }
        setLoading(false);
      });
  };

  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton
            onClick={() => {
              handlePagination(response?.previous);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton
            onClick={() => {
              handlePagination(response?.next);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  useEffect(() => {
    handlePagination(update?.url);
  }, [update]);
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="24px">
          <FormControlLabel
            sx={{
              fontWeight: "600",
              color: colors?.main,
              fontSize: "18px",
            }}
            control={
              <Checkbox
                checked={byMe}
                value={byMe}
                onChange={(e) => {
                  setByMe(e.target.checked);
                }}
              />
            }
            label={t("forms.labels.by_me")}
          />
          {actions?.map((action) => (
            <Box display="flex" gap="20px" alignItems="center" backgroundColor="rgba(211,211,211 , 0.5)" borderRadius="16px" padding="10px">
              <Box sx={{ flex: "1" }}>
                <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={action?.product?.use_admin_image ? (action?.product?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + action?.product?.product_image?.product_image : imagePlaceholder) : action.private_product_image ? process.env.REACT_APP_IMAGE_URL + action.private_product_image : imagePlaceholder} alt="produit" />
              </Box>
              <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{action?.product?.upc}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{action?.product?.designation}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{action?.product?.format}</Typography>
                </Typography>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.treated_at")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{moment(action?.created_at).format("MMMM DD, yyyy")}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.treated_by")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{action?.treated_by}</Typography>
                </Box>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.action")}:{" "}
                  <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>
                    {action_types[`${action?.action}`]} + {t("forms.labels.update")}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ))}
          <Box marginBottom="24px">
            <Pagination />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ListeSuivisEMP;
