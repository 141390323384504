export const columns_produit_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    width: "20%",
    align: "left",
    type: "barcode",
    original_name: "upc",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DÉSIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TRAITÉ LE",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
export const columns_produit_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    width: "20%",
    align: "left",
    type: "barcode",
    original_name: "upc",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TREATED AT",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
export const columns_produit_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    width: "20%",
    align: "left",
    type: "barcode",
    original_name: "upc",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNACIÓN",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMATO",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TRATADO EL",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
