import { Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight, CloseOutlined } from "@mui/icons-material";
import { action_types_en, action_types_es, action_types_fr, action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";

import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { modalStyle } from "../../../theme/ModalStyle";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
const RappelDetailsEMP = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [prod, setProd] = useState([]);
  const { refreshToken } = useLogin();
  const { t } = useTranslation();
  const { id } = useParams();
  const [noProd, setNoProd] = useState({ state: false, data: null });

  const [changeDate, setChangeDate] = useState({ state: false, data: null });
  const [changeDateNoDate, setChangeDateNoDate] = useState({ state: false, data: null });
  const lang = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modification, setModification] = useState(false);
  const [openGestionTraiter, setOpenGestionTraiter] = useState(false);
  const [noDispo, setNoDispo] = useState(false);
  const [noMacth, setNoMatch] = useState(false);
  const [isSubmitingChangeDate, setIsSubmitingChangeDate] = useState(false);

  const [initialValuesGestionTraiter, setInitialValuesGestionTraiter] = useState({
    expiration_date: null,
    location: "",
    quantity: "",
  });

  const validationSchemaTraiter = yup.object({
    expiration_date: yup.date().required(t("validation_required")),
    quantity: yup.number().min(0).required(t("validation_required")),
  });
  const validationSchemaNoDate = yup.object({
    quantity: yup.number().min(0).required(t("validation_required")),
  });
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GetRecallNotifByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
      },
    })
      .then((res) => {
        setProd(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [modification]);
  const InvenduWithDate = (values) => {
    setIsSubmitingChangeDate(true);

    Api.post(
      Urls.TreatRecallWithDate(prod.id),
      {
        expiration_date: values?.expiration_date,
        ...(values?.location && { location: values.location }),
        quantity: values?.quantity,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenGestionTraiter(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDate({ state: !changeDate, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  const InvenduWithoutDate = (values) => {
    setIsSubmitingChangeDate(true);
    Api.post(
      Urls.TreatRecallNoDate(prod.id),
      {
        quantity: values?.quantity,
        ...(values?.location && { location: values.location }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(t("forms.success.update"));
        setOpenGestionTraiter(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDateNoDate({ state: !changeDateNoDate, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  const noProduct = (values) => {
    setIsSubmitingChangeDate(true);
    Api.post(
      Urls.TreatNoProductRecall(id),
      {
        ...(values?.location && { location: values.location }),
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(t("forms.success.update"));
        navigate("/employee/gestion-des-rappels/liste");
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setNoProd({ state: !noProd, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  useEffect(() => {
    if (changeDate.data) InvenduWithDate(changeDate?.data);
  }, [changeDate]);
  useEffect(() => {
    if (changeDateNoDate.data) InvenduWithoutDate(changeDateNoDate.data);
  }, [changeDateNoDate]);
  useEffect(() => {
    if (noProd.data) noProduct(noProd.data);
  }, [noProd]);
  const calculateDaysDiff = (date2) => {
    const date1obj = new Date();
    const date2obj = new Date(date2);
    const timeDiff = date2obj - date1obj;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff;
  };
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box marginBottom="24px" display="flex" flexDirection="column" gap="24px">
          <Box sx={{ cursor: "pointer", flexDirection: "column" }} display="flex" gap="20px" alignItems="center" backgroundColor="rgba(211,211,211 , 0.5)" borderRadius="16px" padding="10px">
            <Box sx={{ flex: "1" }}>
              <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.product?.use_admin_image ? (prod?.product?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod?.product?.product_image?.product_image : imagePlaceholder) : prod.product?.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod?.product?.private_product_image : imagePlaceholder} alt="produit" />
            </Box>
            <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                {t("forms.labels.expiration_date")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? moment(prod?.expiration_date).format("MMMM DD, yyyy") : "-"}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                {t("forms.labels.note")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main, whiteSpace: "break-spaces" }}>{prod?.note}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                DATE {t("navs_marchand.rappel")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.created_at ? moment(prod?.created_at).format("DD MMMM yyyy") : "-"}</Typography>
              </Typography>
            </Box>
          </Box>
          {!prod.treated && (
            <Box display="flex" alignItems="center" justifyContent="center" gap="16px">
              <Button
                disabled={prod?.treated}
                onClick={() => {
                  setInitialValuesGestionTraiter({ ...initialValuesGestionTraiter, location: prod?.product?.location });
                  setOpenGestionTraiter(true);
                }}
                variant="primary"
              >
                {t("traiter")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Modal open={openGestionTraiter} onClose={() => setOpenGestionTraiter(false)}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={() => setOpenGestionTraiter(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Formik
            initialValues={initialValuesGestionTraiter}
            validationSchema={noMacth ? null : noDispo ? validationSchemaNoDate : validationSchemaTraiter}
            enableReinitialize
            onSubmit={(values) => {
              if (noMacth) {
                noProduct(values);
              } else {
                if (noDispo) InvenduWithoutDate(values);
                else InvenduWithDate(values);
              }
            }}
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box>
                    <FormControlLabel
                      sx={{
                        fontWeight: "600",
                        color: colors?.main,
                        fontSize: "18px",
                      }}
                      control={
                        <Checkbox
                          checked={noMacth}
                          size="large"
                          value={noMacth}
                          onChange={(e) => {
                            setNoMatch(e.target.checked);
                            if (e.target.checked) {
                              setFieldValue("expiration_date", null);
                              setFieldValue("quantity", "");
                            }
                          }}
                        />
                      }
                      label={t("no_match")}
                    />
                  </Box>
                  <Box textAlign="center" display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.quantity")}</Typography>{" "}
                    <Box>
                      <Field disabled={noMacth} name="quantity" type="number" placeholder={t("forms.labels.quantity")} as={TextField} />
                      <ErrorMessage name="quantity" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>{" "}
                  <Box textAlign="center" display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}</Typography>{" "}
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="yyyy-MM-dd"
                          value={values.expiration_date}
                          disabled={noDispo || noMacth}
                          minDate={new Date()}
                          InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                          onChange={(value) => {
                            setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "26px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>{t("expire_d")}</Typography> <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{values.expiration_date ? `${calculateDaysDiff(values.expiration_date)} ${t("days")}` : ""}</Typography>{" "}
                  </Box>
                  <Box textAlign="center" display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.localisation")}</Typography>{" "}
                    <Box>
                      <Field name="location" type="input" placeholder={t("forms.labels.localisation")} as={TextField} />
                      <ErrorMessage name="location" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "26px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>{t("fait_par")}</Typography> <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{user?.authenticatedUser?.full_name}</Typography>{" "}
                  </Box>
                  <Box sx={{ display: "flex", gap: "26px" }}>
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>{t("date")}</Typography> <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{moment(new Date()).format("MMMM DD, yyyy")}</Typography>{" "}
                  </Box>
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={noDispo}
                        value={noDispo}
                        onChange={(e) => {
                          setNoDispo(e.target.checked);
                          if (e.target.checked) {
                            setFieldValue("expiration_date", null);
                          }
                        }}
                      />
                    }
                    label={t("forms.labels.date_non_disponible")}
                  />
                  <Box display="flex" justifyContent="center" gap="20px">
                    <Button disabled={isSubmitingChangeDate} type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                    <Button onClick={() => setOpenGestionTraiter(false)} variant="primary">
                      {t("forms.submit.close")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};
export default RappelDetailsEMP;
