import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/authentification/login/Login";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "./hooks/useAuthContext";
import AdminSharedLayout from "./pages/admin/AdminSharedLayout";
import { useEffect, useState } from "react";
import PasswordRecovery from "./pages/authentification/passwordRecovery/PasswordRecovery";
import ListBanniere from "./pages/admin/banniere/ListBanniere";
import AjoutBanniere from "./pages/admin/banniere/AjoutBanniere";
import ListMarchand from "./pages/admin/marchand/ListMarchand";
import AjoutMarchand from "./pages/admin/marchand/AjoutMarchand";
import DbDuplication from "./pages/admin/baseDeDonnee/DbDuplication";
import { Box } from "@mui/material";
import DbList from "./pages/admin/baseDeDonnee/DbList";
import DbEdit from "./pages/admin/baseDeDonnee/DbEdit";
import EditMarchand from "./pages/admin/marchand/EditMarchand";
import ProduitList from "./pages/admin/produit/ProduitList";
import LanceursList from "./pages/admin/lanceursAlerte/LanceursList";
import LanceurAjout from "./pages/admin/lanceursAlerte/LanceurAjout";
import LanceurEdit from "./pages/admin/lanceursAlerte/LanceurEdit";
import AjoutProduit from "./pages/admin/produit/AjoutProduit";
import ProduitDetails from "./pages/admin/produit/ProduitDetails";
import SearchProduct from "./pages/admin/produit/SearchProduct";
import ProduitsListe from "./pages/lanceur_alerte/produits/ProduitsListe";
import AjoutProduits from "./pages/lanceur_alerte/produits/AjoutProduits";
import ProduitListeSearch from "./pages/lanceur_alerte/produits/ProduitListeSearch";
import RecallList from "./pages/lanceur_alerte/produits/RecallList";
import RecallNotificationDetails from "./pages/lanceur_alerte/produits/RecallNotificationDetails";
import ProduitsAtraiter from "./pages/marchand/produit/ProduitsAtraiter";
import ProduitListM from "./pages/marchand/produit/ProduitListM";
import ProduitsNoExp from "./pages/marchand/produit/ProduitsNoExp";
import MarchandRecallList from "./pages/marchand/produit/MarchandRecallList";
import MarchandActions from "./pages/marchand/produit/MarchandActions";
import MarchandRecallNotificationDetails from "./pages/marchand/produit/MarchandRecallNotificationDetails";
import ListeUtilisateur from "./pages/marchand/users/ListeUtilisateur";
import AjoutUtilisateurs from "./pages/marchand/users/AjoutUtilisateur";
import UpdateUtilisateur from "./pages/marchand/users/UpdateUtilisateur";
import Alerte from "./pages/marchand/preference/Alerte";
import AjoutProduitM from "./pages/marchand/produit/AjoutProduitM";
import UpdateProduit from "./pages/marchand/produit/UpdateProduit";
import SearchProductM from "./pages/marchand/produit/SearchProductM";
import EmployeeSharedLayout from "./pages/employee/EmployeeSharedLayout";
import ProduitsListeEMP from "./pages/employee/produits/ProduitsListeEMP";
import ListeSuivisEMP from "./pages/employee/suivi/ListeSuivisEMP";
import ProductDetailsEMP from "./pages/employee/produits/ProductDetailsEMP";
import ProduitsAtraiterListe from "./pages/employee/produits/ProduitsAtraiterListe";
import ProduitsRappelListeEMP from "./pages/employee/produits/ProduitsRappelListeEMP";
import RappelDetailsEMP from "./pages/employee/produits/RappelDetailsEMP";
import ListeProduitsEMPSearch from "./pages/employee/produits/ListeProduitsEMPSearch";
import Rapport from "./pages/marchand/rapport/Rapport";
import moment from "moment";
import "moment/locale/en-ca";
import "moment/locale/es";
import "moment/locale/fr";
import ProduitListEmptyShelf from "./pages/marchand/produit/ProduitListEmptyShelf";
import Redirect from "./pages/employee/Redirect";
import GestionAffichage from "./pages/marchand/preference/GestionAffichage";
import DetailsAction from "./pages/marchand/produit/DetailsAction";

function App() {
  const [IsLoading, setIsLoading] = useState(true);
  const { user, loading } = useAuthContext();
  moment.locale(localStorage.getItem("i18nextLng") === "fr" ? "fr" : localStorage.getItem("i18nextLng") === "es" ? "es" : "en-ca");

  const navigate = useNavigate();
  const user_type = user?.authenticatedUser?.role;
  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [user]);
  useEffect(() => {
    if (!IsLoading) {
      if (!user?.access) {
        navigate("/login");
      }
    }
  }, [IsLoading]);

  return (
    <Box>
      {IsLoading === false && user_type === 1 && (
        <Box>
          <Routes>
            <Route exact path="" element={<Navigate to="/login" />} />
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/" element={<Outlet />} />
            <Route exact path="mot-de-passe-oublier" element={!user ? <PasswordRecovery /> : <Navigate to="/admin/gestion-des-marchands/liste" />} />
            <Route exact path="login" element={user ? <Navigate to="/admin/gestion-des-marchands/liste" replace /> : <Login />} />
            <Route exact path="/admin" element={!user ? <Navigate to="/login" /> : <Navigate to="/admin/gestion-des-marchands/liste" />} />
            <Route path="/admin" element={!user ? <Navigate to="/login" /> : <AdminSharedLayout />}>
              <Route path="/admin/gestion-des-marchands/liste" element={<ListMarchand />} />
              <Route path="/admin/gestion-des-marchands/ajout" element={<AjoutMarchand type="add" />} />
              <Route path="/admin/gestion-des-marchands/liste/:id" element={<EditMarchand />} />
              <Route path="/admin/gestion-des-bannieres/liste" element={<ListBanniere />} />
              <Route path="/admin/gestion-des-bannieres/ajout" element={<AjoutBanniere key={1} type="add" />} />
              <Route path="/admin/gestion-des-bannieres/liste/:id" element={<AjoutBanniere key={2} type="update" />} />
              <Route path="/admin/gestion-des-bds/liste" element={<DbList />} />
              <Route path="/admin/gestion-des-bds/liste/:id" element={<DbEdit />} />
              <Route path="/admin/gestion-des-bds/ajout" element={<DbDuplication />} />
              <Route path="/admin/gestion-des-produits/liste" element={<ProduitList />} />
              <Route path="/admin/gestion-des-produits/liste/search/:id" element={<SearchProduct />} />
              <Route path="/admin/gestion-des-produits/ajout" element={<AjoutProduit />} />
              <Route path="/admin/gestion-des-produits/liste/:id" element={<ProduitDetails />} />
              <Route path="/admin/gestion-des-lanceurs-alerte/liste" element={<LanceursList />} />
              <Route path="/admin/gestion-des-lanceurs-alerte/ajout" element={<LanceurAjout />} />
              <Route path="/admin/gestion-des-lanceurs-alerte/liste/:id" element={<LanceurEdit />} />
            </Route>
          </Routes>
        </Box>
      )}
      {IsLoading === false && user_type === 2 && (
        <Box>
          <Routes>
            <Route exact path="" element={<Navigate to="/login" />} />
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/" element={<Outlet />} />
            <Route exact path="mot-de-passe-oublier" element={!user ? <PasswordRecovery /> : <Navigate to="/marchand/gestion-des-produit/liste" />} />
            <Route exact path="login" element={user ? <Navigate to="/marchand/gestion-des-produit/liste" replace /> : <Login />} />
            <Route exact path="/marchand" element={!user ? <Navigate to="/login" /> : <Navigate to="/marchand/gestion-des-produit/liste" />} />
            <Route path="/marchand" element={!user ? <Navigate to="/login" /> : <AdminSharedLayout />}>
              <Route path="/marchand/gestion-des-produit/liste" element={<ProduitListM />} />
              <Route path="/marchand/gestion-des-produit/ajout" element={<AjoutProduitM />} />
              <Route path="/marchand/gestion-des-rapport/ajout" element={<Rapport />} />
              <Route path="/marchand/gestion-des-produit/liste/:id" element={<UpdateProduit />} />
              <Route path="/marchand/gestion-des-utilisateurs/liste" element={<ListeUtilisateur />} />
              <Route path="/marchand/gestion-des-tablette/liste" element={<ProduitListEmptyShelf />} />

              <Route path="/marchand/gestion-des-utilisateurs/liste/:id" element={<UpdateUtilisateur />} />
              <Route path="/marchand/gestion-des-preferences/ajout" element={<Alerte />} />
              <Route path="/marchand/gestion-des-produit/liste/search/:id" element={<SearchProductM />} />
              <Route path="/marchand/gestion-des-utilisateurs/ajout" element={<AjoutUtilisateurs />} />
              <Route path="/marchand/gestion-des-rappel/liste" element={<MarchandRecallList />} />
              <Route path="/marchand/gestion-des-rappel/liste/:id" element={<MarchandRecallNotificationDetails />} />
              <Route path="/marchand/gestion-des-suivi/liste" element={<MarchandActions />} />
              <Route path="/marchand/gestion-des-suivi/liste/:id" element={<DetailsAction />} />

              <Route path="/marchand/gestion-des-affichage/ajout" element={<GestionAffichage />} />
              <Route path="/marchand/gestion-des-date-non-dispo/liste" element={<ProduitsNoExp />} />
              <Route path="/marchand/gestion-des-produits-a-traiter/liste" element={<ProduitsAtraiter />} />
            </Route>
          </Routes>
        </Box>
      )}
      {IsLoading === false && user_type === 4 && (
        <Box>
          <Routes>
            <Route exact path="" element={<Navigate to="/login" />} />
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route exact path="mot-de-passe-oublier" element={!user ? <PasswordRecovery /> : <Navigate to="/lanceur-alerte/gestion-des-produits/liste" />} />
            <Route exact path="login" element={user?.access ? <Navigate to="/lanceur-alerte/gestion-des-produits/liste" replace /> : <Login />} />
            <Route exact path="/lanceur-alerte" element={!user ? <Navigate to="/login" /> : <Navigate to="/lanceur-alerte/gestion-des-produit/liste" />} />
            <Route path="/lanceur-alerte" element={user ? <AdminSharedLayout /> : <Navigate to="/login" />}>
              <Route path="/lanceur-alerte/gestion-des-produits/liste" element={<ProduitsListe />} />
              <Route path="/lanceur-alerte/gestion-des-produits/liste/:id" element={<AjoutProduits />} />
              <Route path="/lanceur-alerte/gestion-des-produits/liste/search/:id" element={<ProduitListeSearch />} />
              <Route path="/lanceur-alerte/gestion-des-produits/rappel/liste" element={<RecallList />} />
              <Route path="/lanceur-alerte/gestion-des-produits/rappel/liste/:id" element={<RecallNotificationDetails />} />
            </Route>
          </Routes>
        </Box>
      )}
      {IsLoading === false && user_type === 3 && (
        <Box>
          <Routes>
            <Route exact path="" element={<Redirect />} />
            <Route exact path="/" element={<Redirect />} />
            <Route path="/" element={<Outlet />} />
            <Route exact path="mot-de-passe-oublier" element={!user ? <PasswordRecovery /> : <Navigate to="/employee/gestion-des-produits/liste" />} />
            <Route exact path="login" element={user?.access ? <Navigate to="/employee/gestion-des-produits/liste" replace /> : <Login />} />
            <Route exact path="/employee" element={!user ? <Navigate to="/login" /> : <Redirect />} />
            <Route path="/employee" element={user ? <EmployeeSharedLayout /> : <Navigate to="/login" />}>
              <Route path="/employee/gestion-des-produits/liste/search/:id" element={<ListeProduitsEMPSearch />} />
              <Route path="/employee/gestion-des-produits/liste" element={<ProduitsListeEMP />} />
              <Route path="/employee/gestion-des-produits/liste/:id" element={<ProductDetailsEMP showField={true} />} />
              <Route path="/employee/gestion-des-traiter/liste" element={<ProduitsAtraiterListe />} />
              <Route path="/employee/gestion-des-traiter/liste/:id" element={<ProductDetailsEMP />} />
              <Route path="/employee/gestion-des-rappels/liste" element={<ProduitsRappelListeEMP />} />
              <Route path="/employee/gestion-des-rappels/liste/:id" element={<RappelDetailsEMP />} />
              <Route path="/employee/gestion-des-suivis/liste" element={<ListeSuivisEMP />} />
            </Route>
          </Routes>
        </Box>
      )}
      {IsLoading === false && !user && (
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="" element={<Navigate to={"/login"} />} />
          <Route exact path="/" element={<Navigate to={"/login"} />} />
          <Route exact path="/mot-de-passe-oublier" element={!user ? <PasswordRecovery /> : <Navigate to="/" />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
