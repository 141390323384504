export const categories_list_fr = [
  { value: "AUC", name: "Aucune" },
  { value: "LAI", name: "Laitier" },
  { value: "VIA", name: "Viande" },
  { value: "CHA", name: "Charcuterie" },
  { value: "PHA", name: "Pharmacie" },
  { value: "BOU", name: "Boulangerie" },
  { value: "FLE", name: "Fruits et Légumes" },
  { value: "POI", name: "Poisson" },
  { value: "PAM", name: "Prêt à Manger" },
  { value: "BIE", name: "Bière" },
  { value: "AUT1", name: "Autre 1" },
  { value: "AUT2", name: "Autre 2" },
  { value: "AUT3", name: "Autre 3" },
];
export const categories_list_es = [
  { value: "AUC", name: "Ninguna" },
  { value: "LAI", name: "Lácteo" },
  { value: "VIA", name: "Carne" },
  { value: "CHA", name: "Charcutería" },
  { value: "PHA", name: "Farmacia" },
  { value: "BOU", name: "Panadería" },
  { value: "FLE", name: "Frutas y Verduras" },
  { value: "POI", name: "Pescado" },
  { value: "PAM", name: "Listo para comer" },
  { value: "BIE", name: "Cerveza" },
  { value: "AUT1", name: "Otro 1" },
  { value: "AUT2", name: "Otro 2" },
  { value: "AUT3", name: "Otro 3" },
];
export const categories_list_en = [
  { value: "AUC", name: "None" },
  { value: "LAI", name: "Dairy" },
  { value: "VIA", name: "Meat" },
  { value: "CHA", name: "Delicatessen" },
  { value: "PHA", name: "Pharmacy" },
  { value: "BOU", name: "Bakery" },
  { value: "FLE", name: "Fruits and Vegetables" },
  { value: "POI", name: "Fish" },
  { value: "PAM", name: "Ready to Eat" },
  { value: "BIE", name: "Beer" },
  { value: "AUT1", name: "Other 1" },
  { value: "AUT2", name: "Other 2" },
  { value: "AUT3", name: "Other 3" },
];
export const categories_list_fr_all = [
  { value: "ALL", name: "Toute" },
  { value: "AUC", name: "Aucune" },
  { value: "LAI", name: "Laitier" },
  { value: "VIA", name: "Viande" },
  { value: "CHA", name: "Charcuterie" },
  { value: "PHA", name: "Pharmacie" },
  { value: "BOU", name: "Boulangerie" },
  { value: "FLE", name: "Fruits et Légumes" },
  { value: "POI", name: "Poisson" },
  { value: "PAM", name: "Prêt à Manger" },
  { value: "BIE", name: "Bière" },
  { value: "AUT1", name: "Autre 1" },
  { value: "AUT2", name: "Autre 2" },
  { value: "AUT3", name: "Autre 3" },
];
export const categories_list_en_all = [
  { value: "ALL", name: "All" },
  { value: "AUC", name: "None" },
  { value: "LAI", name: "Dairy" },
  { value: "VIA", name: "Meat" },
  { value: "CHA", name: "Cold Cuts" },
  { value: "PHA", name: "Pharmacy" },
  { value: "BOU", name: "Bakery" },
  { value: "FLE", name: "Fruits and Vegetables" },
  { value: "POI", name: "Fish" },
  { value: "PAM", name: "Ready to Eat" },
  { value: "BIE", name: "Beer" },
  { value: "AUT1", name: "Other 1" },
  { value: "AUT2", name: "Other 2" },
  { value: "AUT3", name: "Other 3" },
];
export const categories_list_es_all = [
  { value: "ALL", name: "Todo" },
  { value: "AUC", name: "Ninguna" },
  { value: "LAI", name: "Lácteos" },
  { value: "VIA", name: "Carne" },
  { value: "CHA", name: "Embutidos" },
  { value: "PHA", name: "Farmacia" },
  { value: "BOU", name: "Panadería" },
  { value: "FLE", name: "Frutas y Verduras" },
  { value: "POI", name: "Pescado" },
  { value: "PAM", name: "Listo para Comer" },
  { value: "BIE", name: "Cerveza" },
  { value: "AUT1", name: "Otro 1" },
  { value: "AUT2", name: "Otro 2" },
  { value: "AUT3", name: "Otro 3" },
];
